import type { ResultOf } from "@/graphql";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo } from "react";
import { MeQuery } from "@/api/queries/User";
import { setLanguageTag } from "@/paraglide/runtime";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";

export let language: "sv" | "en" = "sv";

export type Me = NonNullable<ResultOf<typeof MeQuery>["me"]>;

const MeContext = createContext<Me | null>(null);

const MeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { data } = useQuery(MeQuery, { skip: !isAuthenticated });
  const me = data?.me;

  useEffect(() => {
    language = (me?.language as "sv" | "en") || "sv";
    setLanguageTag(language);
  }, [me?.language]);

  const initialMe = useMemo<Me>(
    () => ({
      __typename: "User",
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      language: "sv",
      profileImage: null,
      jobTitle: "",
      company: {
        __typename: "Company",
        id: "",
        name: "",
        apiKey: "",
        language: "en",
        type: null,
        profileImage: null,
        childCompanies: [],
        parentCompany: {
          __typename: "Company",
          id: "",
          name: "",
        },
        enabledFeatures: {
          __typename: "EnabledFeatures",
          id: "",
          assignments: false,
          calendar: false,
          consultantProjects: false,
          contracts: false,
          screeningForms: false,
          referenceForms: false,
          statusDeadlines: false,
          trustcruit: false,
          interviewBooking: false,
          applicationsRankedWithAI: false,
          alvaLabs: false,
          tengai: false,
          interviewBookingGroups: false,
          allApplicationsPage: false,
          videoInApplications: false,
          aiGeneration: false,
          mediaLibrary: false,
          careerPage: false,
          jobtip: false,
          evaluationForm: false,
        },
      },
      privilegeLevel: "USER",
      subscribedMailNotificationTypes: [],
      subscribedToNotificationsStatus: "NONE",
    }),
    [],
  );

  return <MeContext.Provider value={me || initialMe}>{children}</MeContext.Provider>;
};

const useMe = () => {
  const context = useContext(MeContext);

  if (!context) {
    throw new Error("useMe must be used within a MeProvider");
  }

  return context;
};

export { MeProvider, useMe };
