import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const env = createEnv({
  clientPrefix: "VITE_",
  client: {
    VITE_CURRENT_URL: z.string().url(),
    VITE_SERVER_URL: z.string().url(),
    VITE_APPLICANT_URL: z.string().url(),
    VITE_PDF_SERVER_URL: z.string().url(),
    VITE_CAREER_URL: z.string().url(),
    VITE_API_URL: z.string().url(),
    VITE_STORAGE_BUCKET: z.string(),
    VITE_VERSION: z.string(),
    VITE_NODE_ENV: z.string().default("development"),
  },
  runtimeEnv: {
    VITE_CURRENT_URL: import.meta.env.VITE_CURRENT_URL as string,
    VITE_SERVER_URL: import.meta.env.VITE_SERVER_URL as string,
    VITE_APPLICANT_URL: import.meta.env.VITE_APPLICANT_URL as string,
    VITE_PDF_SERVER_URL: import.meta.env.VITE_PDF_SERVER_URL as string,
    VITE_CAREER_URL: import.meta.env.VITE_CAREER_URL as string,
    VITE_API_URL: import.meta.env.VITE_SERVER_URL as string,
    VITE_STORAGE_BUCKET: import.meta.env.VITE_STORAGE_BUCKET as string,
    VITE_VERSION: import.meta.env.VITE_VERSION as string,
    VITE_NODE_ENV: process.env.NODE_ENV as string,
  },
});
