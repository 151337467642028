import type { PropsWithChildren } from "react";
import { Component } from "react";

import ErrorUpdateMessage from "./ErrorUpdateMessage";

export default class ErrorBoundary extends Component<PropsWithChildren, { hasError: boolean }> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // const chunkFailedMessage = /Loading chunk [\d]+ failed/
    // if (error?.message && chunkFailedMessage.test(error.message)) {
    //   if (!getWithExpiry('chunk_failed')) {
    //     setWithExpiry('chunk_failed', 'true', 10000)
    //     window.location.reload()
    //   }
    // }

    console.error(error);
    if (error.name === "TypeError") {
      return { hasError: true };
    } else {
      return { hasError: false };
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorUpdateMessage />;
    }

    return this.props.children;
  }
}
