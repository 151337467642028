/* eslint-disable */
/** 
* This file contains language specific message functions for tree-shaking. 
* 
*! WARNING: Only import messages from this file if you want to manually
*! optimize your bundle. Else, import from the `messages.js` file. 
* 
* Your bundler will (in the future) automatically replace the index function 
* with a language specific message function in the build step. 
*/
/**
 * 
 * @returns {string}
 */
/* @__NO_SIDE_EFFECTS__ */
export const error_loading_page_no_internet = () => `Någonting har gått fel när sidan skulle läsas in och det beror troligtvis på att du saknar internetanslutning. När anslutningen är tillbaka kan du klicka på "Ladda om sidan" nedan eller ladda om direkt i webbläsaren.`
