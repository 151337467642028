/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useRef } from "react";
import getUrls from "@/utils/getUrls";
import { ApolloClient, ApolloProvider, from, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { RetryLink } from "@apollo/client/link/retry";
import { relayStylePagination } from "@apollo/client/utilities";
import { useAuth0 } from "@auth0/auth0-react";
import { sha256 } from "crypto-hash";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";

interface ApolloProviderValue {
  activeCompanyId: string | undefined;
  setActiveCompanyId: (id: string | undefined) => void;
}

const ApolloProviderContext = createContext<ApolloProviderValue>({
  activeCompanyId: undefined,
  setActiveCompanyId: () => null,
});

const ApolloProviderWithAuth0: FC<PropsWithChildren> = ({ children }) => {
  const [activeCompanyId, setActiveCompanyId] = useLocalStorage<string>("activeCompanyId", undefined);
  const client = useRef<ApolloClient<any> | null>(null);
  const history = useHistory();
  const { getAccessTokenSilently, logout } = useAuth0();

  if (!client.current) {
    const httpLink = new BatchHttpLink({
      uri: getUrls().apiUrl,
      batchMax: 5,
      batchInterval: 20,
      fetch,
    });

    // const httpLink = new HttpLink({
    //   uri: getUrls().apiUrl + "/graphql",
    //   fetch,
    // });

    const errorLink = onError((error) => {
      const { graphQLErrors } = error;
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          if (message === "You are not allowed to change data on another account!") {
            toast.error("Du får inte ändra på data på ett annat konto");
          }

          if (message === "missing_write_privileges") {
            toast.error("Du saknar behörighet att ändra den här typen av data.");
          }

          if (message === "missing_read_privileges") {
            history.replace("/not-authorized");
          }

          if (
            message === "not_logged_in" ||
            message === "User is not authenticated" ||
            message === "You are not an authorized user!"
          ) {
            if (!history.location.pathname.includes("/login")) {
              void logout({
                logoutParams: {
                  returnTo: getUrls().redirectUrl,
                },
              });
            }
          }
        });
      }
    });

    const authLink = setContext(async (_, { headers }) => {
      const viewAsUserId = JSON.parse(localStorage.getItem("viewAsUserId") || '""');
      const activeCompanyId = JSON.parse(localStorage.getItem("activeCompanyId") || '""');

      let token: string | null = null;
      try {
        token = await getAccessTokenSilently({
          authorizationParams: {
            audience: "https://server.cruitive.com",
            scope: "openid email profile",
          },
        });
      } catch (e) {
        console.error(e);
      }

      return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        headers: {
          ...headers,
          view_as_user_id: viewAsUserId,
          active_company_id: activeCompanyId,
          authorization: token ? `Bearer ${token}` : "",
          // Accept: 'multipart/mixed;deferSpec=20220824, application/json',
        },
      };
    });

    const retryLink = new RetryLink();

    const keyArgs = (args: Record<string, any> | null) => {
      return Object.keys(args || {}).filter((key) => key !== "after" && key !== "limit");
    };

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            activityFeed: relayStylePagination(keyArgs),
            adminApplications: relayStylePagination(keyArgs),
            allMatchingCriterias: relayStylePagination(keyArgs),
            applicantMessages: relayStylePagination(keyArgs),
            assignmentBoards: relayStylePagination(keyArgs),
            applicantsAdmin: relayStylePagination(keyArgs),
            applications: relayStylePagination(keyArgs),
            applicationScreeningFormParticipations: relayStylePagination(keyArgs),
            availableMatchingEvents: relayStylePagination(keyArgs),
            calendarEventsList: relayStylePagination(keyArgs),
            childsEventMatches: relayStylePagination(keyArgs),
            childCompanysMatchingEvents: relayStylePagination(keyArgs),
            childCompanysMatchingEventParticipants: relayStylePagination(keyArgs),
            comments: relayStylePagination(keyArgs),
            companyApplicants: relayStylePagination(keyArgs),
            companyApplicantsSimple: relayStylePagination(keyArgs),
            companiesAdmin: relayStylePagination(keyArgs),
            companyApplicantApplications: relayStylePagination(keyArgs),
            companyCompetences: relayStylePagination(keyArgs),
            companyUsers: relayStylePagination(keyArgs),
            consultants: relayStylePagination(keyArgs),
            consultantCustomers: relayStylePagination(keyArgs),
            consultantProjectCosts: relayStylePagination(keyArgs),
            consultantProjects: relayStylePagination(keyArgs),
            contactPersons: relayStylePagination(keyArgs),
            contracts: relayStylePagination(keyArgs),
            documents: relayStylePagination(keyArgs),
            errors: relayStylePagination(keyArgs),
            eventTriggers: relayStylePagination(keyArgs),
            forms: relayStylePagination(keyArgs),
            groupCompanies: relayStylePagination(keyArgs),
            groupCompanyApplicants: relayStylePagination(keyArgs),
            matchingEventProfiles: relayStylePagination(keyArgs),
            groupCompanyCompanies: relayStylePagination(keyArgs),
            hostEventMatches: relayStylePagination(keyArgs),
            hostedMatchingEvents: relayStylePagination(keyArgs),
            identifiedNeeds: relayStylePagination(keyArgs),
            interviews: relayStylePagination(keyArgs),
            invitedUsers: relayStylePagination(keyArgs),
            interviewGuideTemplates: relayStylePagination(keyArgs),
            matchingEventCompanyInvitations: relayStylePagination(keyArgs),
            matchingEventCompanyRequests: relayStylePagination(keyArgs),
            matchingEventParticipants: relayStylePagination(keyArgs),
            matchingEventTalentProfiles: relayStylePagination(keyArgs),
            messageTemplates: relayStylePagination(keyArgs),
            messageTemplatesCustom: relayStylePagination(keyArgs),
            notes: relayStylePagination(keyArgs),
            notifications: relayStylePagination(keyArgs),
            pageTemplates: relayStylePagination(keyArgs),
            pageVersions: relayStylePagination(keyArgs),
            participantsEventMatches: relayStylePagination(keyArgs),
            recruitmentApplicantMessages: relayStylePagination(keyArgs),
            recruitmentsAdmin: relayStylePagination(keyArgs),
            recruitmentsPage: relayStylePagination(keyArgs),
            recruitmentsSimple: relayStylePagination(keyArgs),
            recruitmentsPageAdmin: relayStylePagination(keyArgs),
            recruitmentTemplates: relayStylePagination(keyArgs),
            referenceForms: relayStylePagination(keyArgs),
            screeningForms: relayStylePagination(keyArgs),
            talentProfilesOfEvent: relayStylePagination(keyArgs),
            applicantsApplications: relayStylePagination(keyArgs),
            hostsEventMatches: relayStylePagination(keyArgs),
            interviewBookingGroupApplications: relayStylePagination(keyArgs),
            applicationsCompaniesFilter: relayStylePagination(keyArgs),
            applicationsRecruitmentsFilter: relayStylePagination(keyArgs),
            applicationStatusesFilter: relayStylePagination(keyArgs),
            templatePageBlocks: relayStylePagination(keyArgs),
          },
        },
      },
    });

    // function createNestedObject<T>(path: (string | number)[], data: T): object {
    //   const result = {}

    //   set(result, path, data)

    //   // Fill previous array elements with empty objects
    //   if (typeof last(path) === 'number') {
    //     const arrayPath = path.slice(0, -1)
    //     const arrayLength = last(path) as number

    //     set(
    //       result,
    //       arrayPath,
    //       times(arrayLength, (index) => (index === arrayLength - 1 ? data : {})),
    //     )
    //   }

    //   return result
    // }

    // const deferredMergeLink = new ApolloLink((operation, forward) => {
    //   return forward(operation).map((response) => {
    //     if (!('path' in response) || !Array.isArray(response.path)) {
    //       return response
    //     }

    //     const data = response.data as Record<string, unknown>
    //     const path = response.path as (string | number)[]

    //     const nestedData = createNestedObject(path, data)

    //     return { ...response, data: nestedData }
    //   })
    // })

    // setInterval(async () => {
    //   const data = await client.current?.query({
    //     query: gql`
    //       query {
    //         companyIds
    //       }
    //     `,
    //     fetchPolicy: "network-only",
    //   });

    // }, 100);

    const persistedLink = createPersistedQueryLink({ sha256 });
    const link = from([retryLink, authLink, errorLink, httpLink, persistedLink]);

    client.current = new ApolloClient({
      link,
      cache,
      name: "cruitive-app",
      version: "2.1.4",
    });
  }

  return (
    <ApolloProviderContext.Provider value={{ activeCompanyId, setActiveCompanyId }}>
      <ApolloProvider client={client.current}>{children}</ApolloProvider>
    </ApolloProviderContext.Provider>
  );
};

export const useApolloWithAuth0 = () => useContext(ApolloProviderContext);

export default ApolloProviderWithAuth0;
