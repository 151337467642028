import type { USER_ROLE_PRIVILEGE } from "@/gql/graphql";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useMemo } from "react";

import { useMe } from "./MeProvider";

interface GeneralPrivilegeProviderValue {
  isAdmin: boolean;
  hasAccess: (data: { readPrivileges?: USER_ROLE_PRIVILEGE[]; writePrivileges?: USER_ROLE_PRIVILEGE[] }) => boolean;
}

const GeneralPrivilegeContext = createContext<GeneralPrivilegeProviderValue>({
  isAdmin: false,
  hasAccess: () => false,
});

const GeneralPrivilegeProvider: FC<PropsWithChildren> = ({ children }) => {
  const me = useMe();

  const isAdmin = useMemo(
    () => me.role?.accessLevel === "ADMIN" || me.privilegeLevel === "SUPER_ADMIN",
    [me.role?.accessLevel, me.privilegeLevel],
  );

  const hasAccess = ({
    readPrivileges,
    writePrivileges,
  }: {
    readPrivileges?: USER_ROLE_PRIVILEGE[];
    writePrivileges?: USER_ROLE_PRIVILEGE[];
  }) => {
    if (me.role?.accessLevel === "ADMIN") {
      return true;
    }

    if (writePrivileges) {
      const hasAllWritePrivileges = writePrivileges.every((writePrivilege) => {
        return me.role?.writePrivileges.includes(writePrivilege);
      });

      if (!hasAllWritePrivileges) {
        return false;
      }
    }

    if (readPrivileges) {
      const hasAllReadPrivileges = readPrivileges.every((readPrivilege) => {
        return me.role?.readPrivileges.includes(readPrivilege) || me.role?.writePrivileges.includes(readPrivilege);
      });

      if (!hasAllReadPrivileges) {
        return false;
      }
    }

    return true;
  };

  return <GeneralPrivilegeContext.Provider value={{ hasAccess, isAdmin }}>{children}</GeneralPrivilegeContext.Provider>;
};

const useGeneralPrivilege = () => {
  const context = useContext(GeneralPrivilegeContext);
  if (!context) throw new Error("useGeneralPrivilege must be used within a GeneralPrivilegeProvider");
  return context;
};

export { GeneralPrivilegeProvider, useGeneralPrivilege };
