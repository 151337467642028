import type { FC } from "react";
import { useEffect, useState } from "react";
import ServerDownImage from "@/assets/server_down.svg";
import UpdateImage from "@/assets/undraw_update.svg";
import * as m from "@/paraglide/messages.js";
import { MdUpdate } from "react-icons/md";
import { useNetworkState } from "react-use";

import { Button } from "@cruitive/ui/button";

const ErrorUpdateMessage: FC = () => {
  const { online: _online } = useNetworkState();
  const [online, setOnline] = useState<null | undefined | boolean>(null);

  useEffect(() => {
    if (online == null || online === undefined) {
      setOnline(_online);
    }
  }, [online, _online]);

  const [loading, setLoading] = useState(false);
  const handleUpdate = () => {
    setLoading(true);
    window.location.reload();
  };

  if (online == null) {
    return <div />;
  }

  return (
    <div className="flex-center min-h-screen bg-background p-12">
      <div className="flex max-w-2xl flex-col items-center">
        {!online && <img src={ServerDownImage} alt="Server down" />}
        {online && <img src={UpdateImage} alt="Update" />}
        <h1 className="mt-10 text-center text-5xl font-bold text-foreground">
          {online && m.cruitive_updated()}
          {!online && m.something_went_wrong()}
        </h1>
        <p className="mt-4 text-center text-xl text-muted-foreground">
          {online && m.cruitive_update_refresh()}
          {!online && m.error_loading_page_no_internet()}
        </p>
        <Button disabled={loading} onClick={handleUpdate} size="lg" className="mt-6 w-full max-w-lg text-xl">
          <MdUpdate className={`mr-2 ${loading && "animate-spin"}`} />
          {m.reload_page()}
        </Button>
      </div>
    </div>
  );
};

export default ErrorUpdateMessage;
