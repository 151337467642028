import { env } from "@/env";

const getUrls = (): {
  redirectUrl: string;
  jobsUrl: string;
  apiUrl: string;
  currentUrl: string;
  pdfServerUrl: string;
  userNotFoundRedirectUrl: string;
  careerUrl: string;
} => {
  let currentUrl = window.location.origin || env.VITE_CURRENT_URL;

  if (!currentUrl.includes("https://") && !currentUrl.includes("http://")) {
    currentUrl = "https://" + currentUrl;
  }

  const redirectUrl = currentUrl + "/login";
  const userNotFoundRedirectUrl = currentUrl + "/user-not-found";

  const jobsUrl = env.VITE_APPLICANT_URL;
  const careerUrl = env.VITE_CAREER_URL;
  const apiUrl = env.VITE_SERVER_URL; // "https://fcc0cok8o88c8gcosw8488c0.server.farmtid.com"; // env.VITE_SERVER_URL; // "https://cruitive-backend-staging-564330508907.europe-north1.run.app/graphql";
  const pdfServerUrl = env.VITE_PDF_SERVER_URL;

  return {
    currentUrl,
    redirectUrl,
    jobsUrl,
    apiUrl,
    pdfServerUrl,
    userNotFoundRedirectUrl,
    careerUrl,
  };
};

export default getUrls;
