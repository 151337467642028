/* eslint-disable no-restricted-properties */
import CoreLayout from "@/base-components/CoreLayout";
import ErrorBoundary from "@/components/ErrorBoundary";
import ApolloProviderWithAuth0 from "@/providers/ApolloProviderWithAuth0";
import { CompanyProvider } from "@/providers/CompanyProvider";
import { MeProvider } from "@/providers/MeProvider";
import { NavigationProvider } from "@/providers/NavigationProvider";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

import "@/styles/base.css";
import "@/styles/index.css";

import getUrls from "@/utils/getUrls";
import lazyWithRetry from "@/utils/lazyWithRetry";
import { Auth0Provider } from "@auth0/auth0-react";
import { init } from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import type { FC } from "react";
import React, { Suspense } from "react";
import { Toaster } from "react-hot-toast";

import "react-loading-skeleton/dist/skeleton.css";

import { createBrowserHistory } from "history";
import { Route, Router, Switch } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";


import LoadingHome from "./components/LoadingHome";
import { TooltipProvider } from "./components/ui/tooltip";
import { env } from "./env";
import { GeneralPrivilegeProvider } from "./providers/GeneralPrivilegeProvider";
import { ThemeProvider } from "./providers/ThemeProvider";

console.log("React version:", React.version);

const AdminRoute = lazyWithRetry(() => import("@/routes/Admin/AdminRoute"));
const SignupRoute = lazyWithRetry(() => import("@/routes/SignupRoute"));
const NotAuthorized = lazyWithRetry(() => import("@/routes/NotAuthorized"));
const UserNotFoundRoute = lazyWithRetry(() => import("@/routes/UserNotFoundRoute"));
const LoginRoute = lazyWithRetry(() => import("@/routes/LoginRoute"));
const AppRoute = lazyWithRetry(() => import("@/routes/AppRoute"));

console.log("Version:", env.VITE_VERSION, getUrls().currentUrl);

if (process.env.NODE_ENV === "production") {
  init({
    dsn: "https://5617b598c4ba48eaaa4339b11bed0137@o304107.ingest.sentry.io/1757675",
    integrations: [new BrowserTracing()],
    release: `cruitive-app@${env.VITE_VERSION}`,
    ignoreErrors: ["ResizeObserver loop limit exceeded"],
    beforeSend(event, hint) {
      const originalExceptionMsg =
        typeof hint.originalException === "string"
          ? hint.originalException
          : (hint.originalException as { message?: string }).message;

      const isNonErrorException =
        event.exception?.values?.[0]?.value?.startsWith("Non-Error exception captured") ||
        originalExceptionMsg?.startsWith("Non-Error exception captured");

      if (isNonErrorException) {
        return null;
      }

      return event;
    },
  });
}

const urls = getUrls();

// eslint-disable-next-line
if (typeof (window as any).global === "undefined") {
  // eslint-disable-next-line
  (window as any).global = window;
}

const Index: FC = () => {
  return (
    <Router history={createBrowserHistory()}>
      <ThemeProvider>
        <Auth0Provider
          clientId="ODGMaMTOt27fNG1nFDGPcySrHx37H1OC"
          domain="auth.cruitive.com"
          authorizationParams={{
            redirect_uri: urls.currentUrl,
            audience: "https://server.cruitive.com",
          }}
        >
          <ApolloProviderWithAuth0>
            <>
              <QueryParamProvider adapter={ReactRouter5Adapter}>
                <CoreLayout>
                  <MeProvider>
                    <CompanyProvider>
                      <GeneralPrivilegeProvider>
                        <NavigationProvider>
                          <ErrorBoundary>
                            <TooltipProvider>
                              <Suspense fallback={<LoadingHome />}>
                                <Switch>
                                  <Route path="/login" component={LoginRoute} />
                                  <Route path="/signup" component={SignupRoute} exact={true} />
                                  <Route path="/not-authorized" component={NotAuthorized} exact={true} />
                                  <Route path="/user-not-found" component={UserNotFoundRoute} exact={true} />
                                  <Route path="/admin" component={AdminRoute} />
                                  <Route path="/" component={AppRoute} />
                                </Switch>
                              </Suspense>
                            </TooltipProvider>
                          </ErrorBoundary>
                          <Toaster
                            toastOptions={{
                              className: "",
                              style: {
                                backgroundColor: "#374151",
                                color: "#fff",
                                padding: "0.75rem",
                                zIndex: 11000,
                                fontSize: ".875rem",
                              },
                              success: {
                                iconTheme: {
                                  primary: "#3DB6A8",
                                  secondary: "#fff",
                                },
                              },
                              error: {
                                iconTheme: {
                                  primary: "#ef4444",
                                  secondary: "#fff",
                                },
                              },
                            }}
                          />
                        </NavigationProvider>
                      </GeneralPrivilegeProvider>
                    </CompanyProvider>
                  </MeProvider>
                </CoreLayout>
              </QueryParamProvider>
            </>
          </ApolloProviderWithAuth0>
        </Auth0Provider>
      </ThemeProvider>
    </Router>
  );
};

export default Index;
