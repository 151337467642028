import type { ResultOf } from "@/graphql";
import type { FC, PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import CompanyQuery from "@/api/queries/Company/CompanyQuery";
import { useQuery } from "@apollo/client";
import { useAuth0 } from "@auth0/auth0-react";
import { matchPath, useLocation } from "react-router-dom";

import { useApolloWithAuth0 } from "./ApolloProviderWithAuth0";
import { useMe } from "./MeProvider";

type Company = NonNullable<ResultOf<typeof CompanyQuery>["company"]>;

const initialCompany: Company = {
  createdAt: "",
  applicationCount: 0,
  childCompanies: [],
  slug: "",
  id: "",
  name: "",
  subdomain: "",
  orgNumber: "",
  primaryColor: "",
  shortPresentation: "",
  postalCode: "",
  language: "sv",
  postalAddress: "",
  municipality: "",
  websiteUrl: "",
  apiKey: "",
  type: null,
  profileImage: null,
  defaultRecruitmentTemplate: null,
  companyFont: null,
  companyWebFont: "",
  titleFont: "",
  textFont: "",
  users: [],
  parentCompany: null,
  enabledFeatures: {
    id: "",
    assignments: false,
    calendar: false,
    consultantProjects: false,
    contracts: false,
    screeningForms: false,
    referenceForms: false,
    trustcruit: false,
    aiGeneration: false,
    allApplicationsPage: false,
    careerPage: false,
    evaluationForm: false,
    interviewBooking: false,
    mediaLibrary: false,
    statusDeadlines: false,
    videoInApplications: false,
    applicationsRankedWithAI: false,
    alvaLabs: false,
    interviewBookingGroups: false,
    tengai: false,
    jobtip: false,
  },
};

const CompanyContext = createContext<CompanyProviderValue | null>(null);

interface CompanyProviderValue {
  activeCompany: Company;
  activeCompanyHasParentCompany: boolean;
  groupCompany: Company | null;
  isViewingGroupCompany: boolean;
}

const CompanyProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth0();
  const { company: myCompany } = useMe();
  const { pathname } = useLocation();

  const [activeCompanyHasParentCompany, setActiveCompanyHasParentCompany] = useState<boolean>(false);
  const [activeCompanyId, setActiveCompanyId] = useState<string>(myCompany.id);
  const [isViewingGroupCompany, setIsViewingGroupCompany] = useState(false);

  useEffect(() => {
    // Check the URL to determinie if we are viewing a group company, regular company or child company
    const customMatch = matchPath<{ companyId: string }>(pathname, {
      path: `/company/:companyId`,
      exact: false,
      strict: true,
    });

    const companyId = customMatch?.params.companyId;
    if (companyId) {
      setActiveCompanyId(companyId);
      setIsViewingGroupCompany(false);
    } else {
      if (myCompany.type === "GROUP" || myCompany.type === "GROUP_EVENT_MATCHING") {
        setActiveCompanyId(myCompany.id);
        setIsViewingGroupCompany(true);
      }
    }
  }, [pathname, myCompany]);

  const handleSetActiveCompany = (companyData: { company?: Company | null }) => {
    if (companyData.company?.parentCompany) {
      setActiveCompanyHasParentCompany(true);
    } else {
      setActiveCompanyHasParentCompany(false);
    }
  };

  const initialValue = useMemo<CompanyProviderValue>(
    () => ({
      activeCompany: { ...initialCompany },
      groupCompany: { ...initialCompany },
      isViewingGroupCompany,
      activeCompanyHasParentCompany,
    }),
    [isViewingGroupCompany, activeCompanyHasParentCompany],
  );

  // Get company from url
  const { data: activeCompany } = useQuery(CompanyQuery, {
    skip: !isAuthenticated || !activeCompanyId,
    variables: { id: activeCompanyId },
    onCompleted: handleSetActiveCompany,
  });

  // Get company from useMe
  const { data: rootCompany } = useQuery(CompanyQuery, {
    skip: !isAuthenticated || !myCompany.id,
    variables: { id: myCompany.id },
  });

  const { activeCompanyId: apolloActiveCompanyId, setActiveCompanyId: apolloSetActiveCompanyId } = useApolloWithAuth0();

  const value = useMemo<CompanyProviderValue>(() => {
    if (!!rootCompany || !!activeCompany) {
      return {
        activeCompany: activeCompany?.company ?? rootCompany?.company ?? initialCompany,
        groupCompany:
          rootCompany?.company?.type === "GROUP" || rootCompany?.company?.type === "GROUP_EVENT_MATCHING"
            ? rootCompany.company
            : null,
        isViewingGroupCompany,
        activeCompanyHasParentCompany,
      } as CompanyProviderValue;
    } else {
      return initialValue;
    }
  }, [activeCompany, rootCompany, isViewingGroupCompany, initialValue, activeCompanyHasParentCompany]);

  useEffect(() => {
    if (value.activeCompany.id !== "" && value.activeCompany.id !== apolloActiveCompanyId) {
      apolloSetActiveCompanyId(value.activeCompany.id);
    } else if (!value.activeCompany) {
      apolloSetActiveCompanyId(undefined);
    }
  }, [value.activeCompany, apolloActiveCompanyId, apolloSetActiveCompanyId]);

  return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

const useCompany = () => {
  const context = useContext(CompanyContext);
  if (!context) throw new Error("useCompany must be used within a CompanyProvider");
  return context;
};

export { CompanyProvider, useCompany };
