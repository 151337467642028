import type { FC } from "react";
import Spinner from "@/components/ui/spinner";
import useIsMobile from "@/hooks/useIsMobile";
import Skeleton from "react-loading-skeleton";

import logo from "../assets/logo.svg";

const LoadingHome: FC = () => {
  const isMobile = useIsMobile();
  return (
    <div className="absolute inset-0 flex w-full flex-1 flex-col flex-wrap">
      <div
        className="z-50 flex items-center justify-between border-b border-border bg-background"
        style={{ height: 70 }}
      >
        <div className="flex items-center justify-between">
          <div className="flex-center ml-3 mr-4 rounded-full p-0 shadow">
            <img alt="loading" src={logo} className="" width={44} height={44} />
          </div>
          <Skeleton height={30} width={150} />
        </div>

        <div className="mr-3 flex items-center">
          <Skeleton width={40} height={40} circle={true} />
          <div style={{ width: 6 }} />
          <Skeleton width={isMobile ? 40 : 210} height={40} className="rounded-full" circle={isMobile} />
          <div style={{ width: 6 }} />
          <Skeleton width={40} height={40} circle={true} />
        </div>
      </div>
      <div className="flex-center flex-1">
        <Spinner size={32} />
      </div>
    </div>
  );
};

export default LoadingHome;
