import type { FC, PropsWithChildren } from "react";
import LoadingHome from "@/components/LoadingHome";
import getUrls from "@/utils/getUrls";
import { useAuth0 } from "@auth0/auth0-react";
import { Redirect, useLocation } from "react-router-dom";

const CoreLayout: FC<PropsWithChildren> = ({ children }) => {
  const { logout, isLoading, isAuthenticated } = useAuth0();
  const { pathname } = useLocation();

  if (isLoading) {
    return <LoadingHome />;
  }

  if (isAuthenticated && pathname === "/login") {
    return <Redirect to="/" />;
  }

  if (!isAuthenticated && pathname !== "/login" && pathname !== "/signup" && pathname !== "/user-not-found") {
    // logout()
    void logout({
      logoutParams: {
        returnTo: getUrls().redirectUrl,
      },
    });
    return <LoadingHome />;
  }

  return <>{children}</>;
};

export default CoreLayout;
